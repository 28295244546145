<template>
  <div class="admin-create-incident-type-group">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create Incident Type Group</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Form -->
    <div class="form">
      <a-form-item label="Incident Type Group Name">
        <a-input size="large" v-model="form.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="form.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="form.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import guidanceHelpers from "../../../../helpers/guidance";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import incidentTypes from "../../../../api/incident-types";
export default {
  components: { LoadingScreen },
  data() {
    return {
      isSaving: false,
      form: {
        displayName: "",
        scope: 1,
        rank: 0,
      },
    };
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },
  methods: {
    goBack() {
      this.$router.push("/admin/settings/incident-type-groups");
    },

    getValidationErrors() {
      let errors = [];
      if (this.form.displayName.trim().length == 0) {
        errors.push("Please provide a group name");
      }
      return errors;
    },

    save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      let params = {
        ...this.form,
        ownerId: this.selectedOrganisation.id,
        purpose: 2,
      };

      vm.isSaving = true;
      incidentTypes
        .addIncidentType(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Group created successfully");
          vm.$router.push("/admin/settings/incident-type-groups");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error adding incident type");
        });
    },
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-incident-type-group {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 25px;
  }
}
</style>